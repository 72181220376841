<template>
  <div>
    <el-card shadow="never" id="trial-application" :body-style="{
      minHeight: 'calc(100vh - 153px)'
    }">
      <div
          v-for="item in productInfo"
          :key="item.id"
          class="mb-32"
      >
        <!--        <el-row :gutter="16" style="margin-bottom: 16px">
                  <el-col :span="3">
                    <div class="image-container">
                      <img :src="item.productLogo" alt>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div
                        style="cursor: pointer;"
                        @click="toDetailPage(item.productId)"
                    >
                      <el-card
                          class="box-card"
                          shadow="never"
                          :body-style="{
                          ...cardBodyStyle,
                          background: '#DBF7E4',
                          height: '100px'
                        }"
                      >
                        <div class="title" style="color: #4850FF;">
                          {{ item.productName }}
                        </div>
                        <div style="margin-bottom: 4px">
                          <span class="title red-color">开源-共享-合作</span>
                        </div>
                        <div style="color: #727070;">
                          发布于{{ formatter(item.createTime, "yyyy年MM月dd日 hh:mm:ss") }}
                        </div>
                        <div class="footer">
                          <el-tooltip
                              :content="item.environmentList"
                              placement="top"
                          >
                            <div class="apply grey-color">{{ '适用于:' + item.environmentList }}</div>
                          </el-tooltip>
                          <el-tooltip
                              :content="item.chainStatus === 1 ? '产品已上链确权' : '产品未上链确权'"
                              placement="top"
                          >
                            <div class="confirm blue-color">
                              <img src="@/assets/accountCenter/redKey.png" alt/>
                              {{ item.chainStatus === 1 ? '产品已上链确权' : '产品未上链确权' }}
                            </div>
                          </el-tooltip>
                        </div>
                      </el-card>
                    </div>
                  </el-col>

                  <el-col :span="13">
                    <div
                        style="cursor: pointer;"
                        @click="toDetailPage(item.productId)"
                    >
                      <el-card
                          class="box-card"
                          shadow="never"
                          :body-style="{
                          ...cardBodyStyle,
                          background:'#DBF7E4'
                        }"
                      >
                        <div class="description">
                        <span v-for="(i,index) in item.productIntroduction.split('/n')" :key="index">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ i }}
                          <br v-if="index !== item.productIntroduction.split('/n').length - 1"/>
                        </span>
                        </div>
                      </el-card>
                    </div>
                  </el-col>
                </el-row>-->

        <el-row>
          <div style="border-bottom: 2px solid #dcdfe6;line-height: 48px">
            <span>{{ item.applyTime }} </span>
            <span style="color: #4850FF;">{{ item.applyUserName }} {{ item.applyPhone }} </span>
            <span style="color: #F5313D;" v-if="item.applyType === 1">申请试用 </span>
            <span style="color: #F5313D;" v-else>申请签约 </span>
            <span style="color: #4850FF;" v-if="item.contract === 1">{{ item.productName }}</span>
            <span style="color: #4850FF;" v-else>{{ item.productName }}</span>
            <el-button style="float: right;margin-top: 10px;"
                       type="primary" size="mini" @click="download(item)">下载授权
            </el-button>
            <el-button style="float: right;margin-top: 10px;margin-right: 12px"
                       size="mini" @click="deal(item)">查看处理
            </el-button>
            <span style="float: right; color: #F5313D;margin-right: 5px">
                <span v-if="item.applyStatus === 0">申请中</span>
                <span v-if="item.applyStatus === 1">已同意</span>
                <span v-if="item.applyStatus === -1">已忽略</span>
                <span v-if="item.applyStatus === 2">已授权下载</span>
              </span>
          </div>
        </el-row>

      </div>
      <el-empty v-if="productInfo.length === 0"/>
      <el-pagination
          background
          style="margin-top: 16px;text-align: center"
          layout="prev, pager, next"
          @current-change="handlePageChange"
          :hide-on-single-page="true"
          :page-size="pagination.pageSize"
          :current-page="pagination.pageNum"
          :total="total">
      </el-pagination>
    </el-card>

    <el-dialog
        :title=dialogContent.contract
        :visible.sync="dialogVisible"
        width="50%"
        center
    >
      <table cellspacing="0" cellpadding="0">
        <tr>
          <td class="td-label">uid：</td>
          <td class="td-content" style="color: #F5313D;"><span class="ml-16">{{ dialogContent.id }}</span></td>
        </tr>
        <tr>
          <td class="td-label">公司名称：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.companyName }}</span></td>
        </tr>
        <tr>
          <td class="td-label">省市：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.province }}-{{ dialogContent.city }}</span></td>
        </tr>
        <tr>
          <td class="td-label">申请人：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.name }}</span></td>
        </tr>
        <tr>
          <td class="td-label">手机号：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.phone }}</span></td>
        </tr>
        <tr>
          <td class="td-label">备选手机号：</td>
          <td class="td-content"><span class="ml-16">{{
              dialogContent.applyExplain ? dialogContent.applyExplain : '无'
            }}</span></td>
        </tr>
        <tr>
          <td class="td-label">电子邮箱：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.email ? dialogContent.email : '无' }}</span></td>
        </tr>
        <tr>
          <td class="td-label">产品信息来源：</td>
          <td class="td-content"><span class="ml-16">{{ dialogContent.informationSource }}</span></td>
        </tr>
        <tr>
          <td class="td-label">公司类型：</td>
          <td class="td-content"><span class="ml-16">{{
              dialogContent.companyType ? dialogContent.companyType : '无'
            }}</span></td>
        </tr>
        <tr>
          <!--          <td class="td-label">申请试用原因：</td>-->
          <td class="td-label">{{ dialogContent.applyType === 1 ? '申请试用原因：' : '企业简介：' }}</td>
          <td class="td-content">
            <span class="ml-16" style="line-height: 24px;display:block;">
              {{ dialogContent.reason }}
            </span>
          </td>
        </tr>
      </table>
      <el-checkbox v-if="dialogContent.applyStatus === 1 && dialogContent.status === 1"
                   style="margin-left: 136px;margin-top: 16px" v-model="checked">
        同意对方下载
        {{ dialogContent.content }} 源码
      </el-checkbox>

      <span slot="footer" class="dialog-footer">
        <div v-if="dialogContent.status === 0">
          <div v-if="dialogContent.applyStatus === 1">
            <el-tag type="success" style="width: 120px;height: 40px;line-height: 40px">已同意</el-tag>
          </div>
          <div v-if="dialogContent.applyStatus === -1">
            <el-tag type="info" style="width: 120px;height: 40px;line-height: 40px">已忽略</el-tag>
          </div>
          <div v-if="dialogContent.applyStatus === 2">
            <el-tag type="success" style="width: 150px;height: 40px;line-height: 40px">已授权下载</el-tag>
          </div>
          <div v-if="dialogContent.applyStatus === 0">
            <el-popconfirm
                title="确定忽略该申请吗？"
                @confirm="neglect"
            >
              <el-button slot="reference" style="width: 120px">忽 略</el-button>
            </el-popconfirm>
            <el-popconfirm
                title="确定同意该申请吗？"
                style="margin-left: 10px"
                @confirm="agree"
            >
              <el-button slot="reference" style="width: 120px" type="primary">同 意</el-button>
            </el-popconfirm>
          </div>
        </div>

        <div v-if="dialogContent.status === 1">
          <div v-if="dialogContent.applyStatus === 1">
            <el-popconfirm
                title="确定授权下载该申请吗？"
                @confirm="authorize"
            >
              <el-button slot="reference" style="width: 120px" type="primary">授权下载</el-button>
            </el-popconfirm>
          </div>
          <div v-if="dialogContent.applyStatus === 2">
            <el-tag type="success" style="width: 150px;height: 40px;line-height: 40px">已授权下载</el-tag>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Col,
  Row,
  Tooltip,
  Dialog,
  Message,
  Checkbox,
  Pagination,
  Empty,
  Tag,
  Popconfirm,
  Loading
} from "element-ui"
import {getApplyList, handleApply} from "@/api/accountCenter";
import {formatter} from "@/util/common";

export default {
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Tooltip.name]: Tooltip,
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [Pagination.name]: Pagination,
    [Empty.name]: Empty,
    [Tag.name]: Tag,
    [Popconfirm.name]: Popconfirm,
    [Message.name]: Message,
  },
  data() {
    return {
      cardBodyStyle: {
        borderRadius: '8px',
        padding: '12px',
      },
      productInfo: [],
      dialogVisible: false,
      dialogContent: {
        contract: '',
        id: '',
        applyType: null,
        companyName: '',
        province:'',
        city:'',
        name: '',
        phone: '',
        applyExplain: '',
        email: '',
        informationSource: '',
        companyType: '',
        reason: '',
        status: '',
        applyStatus: '',
        content: '',
      },
      handApplyParam: {
        applyStatus: '',
        id: ''
      },
      checked: false,
      pagination: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      load: undefined,
    }
  },
  mounted() {
    this.getApplyList();
  },
  methods: {
    formatter,
    getApplyList() {
      this.load = Loading.service({
        target: document.getElementById('trial-application'),
        text: '加载中...'
      })
      getApplyList(this.pagination).then(res => {
        this.load.close()
        this.productInfo = res.data.list;
        this.total = res.data.total
      }).catch(() => {
        this.load.close()
      })
    },
    handlePageChange(currentPage) {
      this.pagination.pageNum = currentPage
      window.scrollTo(0,0)
      this.getApplyList()
    },
    toDetailPage(id) {
      this.$router.push({
        name: 'detail',
        params: {
          id: id
        }
      })
    },
    deal(item) {
      console.log("查看申请资料");
      console.log(item);
      this.dialogContent.status = 0;
      this.dialogContent.applyStatus = item.applyStatus;
      if (item.applyType === 1) {
        this.dialogContent.contract = '申请试用';
      } else {
        this.dialogContent.contract = '申请签约';
      }
      this.dialogContent.id = item.id;
      this.dialogContent.applyType = item.applyType
      this.dialogContent.companyName = item.applyCompanyName;
      this.dialogContent.province = item.province;
      this.dialogContent.city = item.city;
      this.dialogContent.name = item.applyUserName;
      this.dialogContent.phone = item.applyPhone;
      this.dialogContent.applyExplain = item.applyExplain;
      this.dialogContent.email = item.applyEmail;
      this.dialogContent.informationSource = item.informationSource;
      this.dialogContent.companyType = item.companyType;
      this.dialogContent.reason = item.remarks;
      this.dialogContent.content = item.productName;
      this.dialogVisible = true
    },
    download(item) {
      this.dialogContent.status = 1;
      if (item.applyStatus === 1 || item.applyStatus === 2) {
        this.dialogContent.applyStatus = item.applyStatus;
        this.checked = false;
        if (item.applyType === 1) {
          this.dialogContent.contract = '申请试用';
        } else {
          this.dialogContent.contract = '申请签约';
        }
        this.dialogContent.id = item.id;
        this.dialogContent.applyType = item.applyType
        this.dialogContent.companyName = item.applyCompanyName;
        this.dialogContent.province = item.province;
        this.dialogContent.city = item.city;
        this.dialogContent.name = item.applyUserName;
        this.dialogContent.phone = item.applyPhone;
        this.dialogContent.applyExplain = item.applyExplain;
        this.dialogContent.email = item.applyEmail;
        this.dialogContent.informationSource = item.informationSource;
        this.dialogContent.companyType = item.companyType;
        this.dialogContent.reason = item.remarks;
        this.dialogContent.content = item.productName;
        this.dialogVisible = true
      } else if (item.applyStatus === 0) {
        Message.closeAll();
        Message({
          message: '请先在查看处理中同意申请',
          type: 'warning'
        });
      } else if (item.applyStatus === -1) {
        Message.closeAll();
        Message({
          message: '您已忽略该申请，不可授权下载',
          type: 'warning'
        });
      }
    },
    neglect() {
      this.handApplyParam.applyStatus = -1;
      this.handApplyParam.id = this.dialogContent.id;
      handleApply(this.handApplyParam).then(res => {
        if (res.code === 200) {
          this.getApplyList();
          this.dialogVisible = false;
          Message({
            message: '已忽略',
            type: 'success'
          });
        }
      })
    },
    agree() {
      this.handApplyParam.applyStatus = 1;
      this.handApplyParam.id = this.dialogContent.id;
      handleApply(this.handApplyParam).then(res => {
        if (res.code === 200) {
          this.getApplyList();
          this.dialogVisible = false;
          Message({
            message: '已同意',
            type: 'success'
          });
        }
      })
    },
    authorize() {
      if (this.checked) {
        this.handApplyParam.applyStatus = 2;
        this.handApplyParam.id = this.dialogContent.id;
        handleApply(this.handApplyParam).then(res => {
          if (res.code === 200) {
            this.getApplyList();
            this.dialogVisible = false;
            Message({
              message: '已授权下载',
              type: 'success'
            });
          }
        })
      } else {
        Message.closeAll();
        Message({
          message: '请先勾选 同意对方下载 ' + this.dialogContent.content + ' 源码',
          type: 'warning'
        });
      }
    }
  }
}

</script>

<style lang="scss" scoped>
/deep/ .el-card {
  border-radius: 8px;
}

/deep/ .el-popconfirm__main {
  margin-bottom: 8px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;

  img {
    width: 100px;
    height: 100px;
  }
}

.title {
  font-size: 18px;
  color: rgba(16, 16, 16, 100);
  margin-bottom: 8px;
}

.red-color {
  color: #F5313D;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  margin-top: 4px;

  img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  span {
    color: #9ca19f;
  }

  .blue-color {
    color: #4850FF;
  }

  .grey-color {
    color: #727070;
  }

  .apply {
    line-height: 16px;
    font-size: 13px;
    text-overflow: ellipsis; /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
    white-space: nowrap; /* nowrap:规定段落中的文本不进行换行   */
    overflow: hidden; /*超出部分隐藏*/
  }

  .confirm {
    display: flex;
    height: 16px;
    align-items: center;
    font-size: 13px;
  }

  .mb-4 {
    margin-bottom: 4px;
  }
}

.description {
  line-height: 20px;
  height: 100px;
  display: -webkit-box; /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
  -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
  -webkit-line-clamp: 5; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
  overflow: hidden;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-16 {
  margin-left: 16px;
}

.td-label {
  font-size: 14px;
  color: #303133;
  height: 40px;
  width: 120px;
  text-align: center;
}

.td-content {
  font-size: 14px;
  color: #303133;
  height: 40px;
}
</style>
